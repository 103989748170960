import { FC } from 'react';
import { useEffect } from 'react';
import Script from 'next/script';
import $ from 'jquery';

interface ILitresWidget {
  bookTitle: string;
  lfrom: number;
  authorName?: string;
}

const LitresWidget: FC<ILitresWidget> = ({ bookTitle, lfrom, authorName }) => {
  let fix = true;
  useEffect(() => {
    fix = !fix;
    if (fix) return;

    <Script src="//www.litres.ru/static/widgets/buy_widget/js/widget.js" strategy="lazyOnload" />;

    $.get(
      'https://www.litres.ru/ref_widget_art_alltypes/',
      {
        q: bookTitle,
        q_author: authorName,
        lfrom: lfrom,
      },

      function (data) {
        const bookElements = document.querySelectorAll('[data-book-item]');

        if (bookElements.length > 0) {
          bookElements.forEach((book) => {
            const bookNameElement = book.querySelector('[data-widget-litres-book]');
            if (bookNameElement && bookNameElement.textContent === bookTitle) {
              const widgetElement = book.querySelector('[data-litres-widget]');
              widgetElement!.innerHTML = data;
            }
          });
        } else {
          $('#widget').append(data);
        }
      }
    );
  }, []);

  return <div data-litres-widget></div>;
};

export default LitresWidget;
