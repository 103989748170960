import { FC } from 'react';
import Link from 'next/link';
import styles from './BtnReadOnline.module.scss';

interface IBtnReadOnline {
  alias: string;
  target?: string;
  name?: string;
}

const BtnReadOnline: FC<IBtnReadOnline> = ({ alias, target, name }) => {
  return (
    <Link prefetch={false} className={`${styles.btn_orange} ${styles.btn_orange_mobWide}`} href={alias} target={target ? target : '_self'}>
      {name ? name : 'Читать книгу онлайн'}
    </Link>
  );
};

export default BtnReadOnline;
